import React from "react"
import Logo from '../assets/img/logo-caribe.png'
import "./styles/header.scss"
import { Link } from "gatsby"

const Header = ({ siteTitle }) => (
  <header>
    <Link to="/">
      <img src={Logo} />
    </Link>
    <div id="menu">
      <Link to="/">
        <p>About Us</p>
      </Link>
      <Link to="/contact">
        <p>Contact</p>
      </Link>
    </div>
  </header>
)

export default Header
